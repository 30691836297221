.autocomplete-container {
    position: relative;
    width: 100%;
  }
  
  .autocomplete-input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .suggestions-list {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  .suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  