/* src/components/BottomNav.css */
.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }
  
  .nav-items {
    display: flex;
    justify-content: space-around;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-items li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .nav-items li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }
  
  .nav-items li a:hover {
    color: #007bff;
  }
  
  .nav-items li svg {
    width: 24px;
    height: 24px;
  }
  